import request from '@/utils/request';
// 获取充值渠道
export function getDeposit() {
  return request({
    url: '/funds/deposit',
    method: 'get'
  });
}
// 提交充值
export function sendDeposit(data) {
  return request({
    url: '/funds/deposit',
    method: 'post',
    data
  });
}
// 提现
export function getWithdrawal() {
  return request({
    url: '/funds/withdrawal',
    method: 'get',
    needMsg:true
  });
}
// 发送提现
export function setWithdrawal(data) {
  return request({
    url: '/funds/withdrawal',
    method: 'post',
    data
  });
}
// 充值记录
export function getDepositList(params) {
  return request({
    url: '/funds/depositRecord',
    method: 'get',
    params
  });
}
// 提款记录
export function getWithdrawalList(params) {
  return request({
    url: '/funds/withdrawalRecord',
    method: 'get',
    params
  });
}
// 游戏记录
export function getLottery(params) {
  return request({
    url: '/project/lotteryRecord',
    method: 'get',
    params
  });
}
