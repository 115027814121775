<template>
  <div class="rechargeType container van-tab__panel" :style="{ backgroundImage: 'url(img/pro-bg.png)' }">
    <van-loading v-if="loading" />
    <van-form @submit="onSubmit" v-if="!loading">
      <van-cell-group inset style="padding:10px">
        <div class="txt-center">
          <p>
            <svg-icon v-if="type.indexOf('USDT') !== -1" icon-class="USDT" style="width: 40px; height: 40px" />
          <div v-else>
            <van-row gutter="20">
              <van-col span="8">
                <van-icon name="card" class="card" />
              </van-col>
              <van-col span="16" class="txt-left yellow" style="line-height:23px"><b>{{$t('menus.text18')}}</b></van-col>
            </van-row>
          </div>
          </p>
          <div v-if="type.indexOf('USDT') !== -1">
            <div id="qrcode" style="width: 100px; height: 100px; margin: 0 auto"></div>
            <van-cell :title="t('menus.text17')" :value="paymentInfo.coin_address">
              <template #right-icon>
                <van-icon name="todo-list-o" class="copy" @click="copyNumber" />
              </template>
            </van-cell>
          </div>
          <div v-else>
            <van-cell-group class="txt-left">
               <!-- <van-cell
                title="持卡人姓名"
                :value="
                  paymentInfo.account_full_name 
                "
              />
              <van-cell
                title="账户银行名"
                :value="
                  paymentInfo.account_bank_name 
                "
              />
              <van-cell title="银行卡号" :value="paymentInfo.account_number">
                <template #right-icon>
                  <van-icon
                    name="todo-list-o"
                    class="copy"
                    @click="copyNumber"
                  />
                </template>
              </van-cell>
              <van-cell title="所属支行" :value="paymentInfo.account_address" /> -->
<!--              <van-cell title="转账限额" :value="paymentInfo.amount_min + '~' + paymentInfo.amount_max" />-->
            </van-cell-group>
          </div>
        </div>
        <van-field v-model="form.money" :label="t('menus.text19')" :placeholder="t('menus.text20')" class="mt-10" />
        <!-- <van-field name="uploader" label="上传支付凭证" class="uploader">
          <template #input>
            <van-uploader v-model="fileList" :max-count="1" :after-read="afterRead" />
          </template>
        </van-field> -->
        <p class="fz12 txt-left wt90 mg-center con" style="line-height: 25px">
          *{{$t('menus.text15')}}:{{paymentInfo.amount_min}}<br />
          *{{$t('menus.text16')}}:{{paymentInfo.amount_max}}<br />
          <!-- * 支付成功后请上传不大于1MB的jpg、png成功截图 -->
        </p>
      </van-cell-group>
      <div style="margin-top: 16px">
        <van-button round block type="primary" native-type="submit">
          {{$t('menus.submit')}}
        </van-button>
      </div>
    </van-form>
    <input type="number" id="bankNumber" readonly :value="paymentInfo.account_number || paymentInfo.coin_address"
      style="display: none" />
  </div>
</template>

<script setup>
import QRCode from "qrcodejs2";
import store from "@/store";
import { reactive, ref, toRaw, nextTick } from "vue";
import { Toast } from "vant";
import { getDeposit, sendDeposit } from "@/api/money";
import { uploadImg } from "@/api/public";
import { useRouter } from "vue-router";

import { useI18n } from "vue-i18n";
const { t } = useI18n();
const router = useRouter();
const { id, type, payment_type } = router.currentRoute.value.query;
const { userInfo } = store.getters;
const { kefu_url } = userInfo.info;
console.log(router.currentRoute.value);
const form = reactive({
  money: undefined,
  pic_name: "",
  id: id,
});
const loading = ref(true);
const paymentInfo = ref({});
getDeposit().then((res) => {
  paymentInfo.value = res.data.user_payment_accounts.find(
    (item) => item.id == id
  );
  if (paymentInfo.value.coin_address) {
    nextTick(() => {
      new QRCode(document.getElementById("qrcode"), {
        text: paymentInfo.value.coin_address,
        width: 100,
        height: 100,
      });
    });
  }
  loading.value = false;
  console.log(paymentInfo.value);
});
const copyNumber = () => {
  const copyBtn = document.getElementById("bankNumber");
  copyBtn.select(); // 选择对象
  document.execCommand("Copy");
  Toast.success("卡号已复制！");
};
// 图片上传
const fileList = ref([]);
const afterRead = (file) => {
  file.status = "uploading";
  file.message = "上传中...";
  uploadImg(file.file)
    .then((res) => {
      form.pic_name = res.data.path;
      file.status = "done";
      file.message = "上传成功";
    })
    .catch(() => {
      file.status = "failed";
      file.message = "上传失败";
    });
};
const onSubmit = () => {
  Toast.loading({
    duration: 0,
    forbidClick: true,
  });
  sendDeposit(toRaw(form))
    .then((res) => {
      Toast.success(res.msg);
      // setTimeout(() => {
      //   if (payment_type ==='agent_chat'){
      //     window.open(kefu_url)
      //   }else{
      //     router.push('/my')
      //   }
      // }, 1000);
    })
    .catch(() => {
      Toast.clear();
    });
};
</script>

<style lang="less" scoped>
.rechargeType {
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: 0 -50px;
  color: #ddd;
  min-height: 300px;

  .card {
    font-size: 50px;
  }

  .copy {
    margin: 6px 10px 0 10px;
  }
}
</style>
